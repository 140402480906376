body {
    font-family: 'Handlee', cursive; 
    font-size: 1.3rem;
}

#landing {
    height: 100vh;
}

.line-separator {
    border: none;
    height: 8px;
    background-image: linear-gradient(-45deg, yellowgreen, #176d88); 
    width: 85% !important;
}

.jumbotron {
    height: 400px;
    margin: 140px 0 150px 0;
    background-image: linear-gradient(-45deg, #176d88, yellowgreen);
}

.name-section {
    font-family: 'Baloo Bhai', cursive;
    color: #176d88;
    font-size: 2.6rem;
}

#pic-me {
    margin-left: 7%;
}

#pic-mara-me {
    width: 300px;
}

.my-name-navbar, .my-name {
    color: white;
    text-shadow: 2px 2px #176d88;
}

.my-name-navbar:hover {
    color: #176d88 !important;
    text-shadow: 1px 1px white; 
}

.profile-icon {
    font-size: 3rem;
}

.resume {
    font-size: 2.8rem;
}

.section {
    border: 3px solid white;
    border-radius: 25% 0% 25% 0%;
}

.profile-icon:hover, .section:hover {
    color: #176d88 !important;
    border-color: #176d88;
}

.section-navbar:hover {
    color: yellowgreen !important;
    border-color: yellowgreen;
}

.short-intro {
    margin-top: 50px;
}

.navbar-custom {
    background-image: linear-gradient(-45deg, #176d88, yellowgreen);
}

/* to change the color of the little menu button */
.navbar-dark .navbar-toggler {
    border-color: white;
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.btn-git {
    background-color: #176d88;
}

.btn-deploy {
    background-color: coral;
}

.btn-deploy:hover, .btn-git:hover {
    background-color: yellowgreen;
}

.card-columns-project {
    margin-left: 5%;
}

.card-columns-logo {
    column-count: 5;
}

.container-logo {
    border-radius: 0.5%;
}

.card-project, .container-logo, .card-aboutme {
    border: 2px solid #176d88;
    color: #176d88;
}

.project-block {
    position: relative;
}

.overlay-tech {
    transition: .3s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.project-block:hover .card-project, .touch .card-project.unreveal {
    opacity: 0.05;
}

.project-block:hover .overlay-tech, .touch .overlay-tech.reveal {
    opacity: 1;
}

/* for responsive design */
@media screen and (max-width: 1200px) {
    .card-columns-project {
        column-count: 3;
        margin-left: 0;
    }   
}

@media screen and (max-width: 1010px) {
    .card-columns-logo {
        column-count: 4;
    }   
}

@media screen and (max-width: 992px) {
    .card-columns-project {
        column-count: 2;
        margin-left: 4%;
    } 
    
    #pic-me {
        margin-left: 5%;
        margin-right: 2%;
        width: 200px;
    }

    #div-short-intro {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    #div-sections {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    #div-icons {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    .jumbotron {
        height: 330px;
    }
}

@media screen and (max-width: 825px) {
    .my-name {
        font-size: 2.5rem !important; 
    }

    .card-columns-logo {
        column-count: 3;
    }   
}

@media screen and (max-width: 768px) {
    #pic-mara-me {
        width: 95%;
    }

    #pic-me {
        margin-left: 5%;
        width: 150px;
    }

    .card-columns-project {
        column-count: 1;
        margin-left: 20%;
    } 
}

@media screen and (max-width: 650px) {
    .card-columns-logo {
        column-count: 2;
    }  
}

@media screen and (max-width: 576px) {
    #pic-me {
        margin-left: 5%;
        margin-bottom: 5%;
        width: 120px;
    }

    #div-sections {
        clear: both;
    }

    .jumbotron {
        height: 340px;
    }

    .card-columns-project {
        margin-left: 20%;
    } 
    
    .card-columns-logo {
        column-count: 1;
    }

    .my-name-navbar {
        margin-top: 10px;
        font-size: 1.3rem;
    }

    .profile-icon-navbar {
        font-size: 2rem;
    }

    .navbar-dark .navbar-toggler-icon {
        font-size: 1rem;
    }

    .section-navbar {
        font-size: 0.7rem;
        border: 2px solid white
    }
}

@media screen and (max-width: 450px) {
    .card-columns-project {
        margin-left: 13%;
    }
    
    .my-name {
        font-size: 2rem !important;
    }

    .intro {
        font-size: 1.3rem !important;
    }
}

